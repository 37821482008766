import React from "react";
import {container, left, p, icon} from "./note.module.scss";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Note({ children }) {
    return (
        <center>
            <div className={container}>
                <div className={left}>
                    <FontAwesomeIcon className={icon} icon={faInfo} />
                </div>
                <p className={p}>{children}</p>
            </div>
        </center>
    );
}
