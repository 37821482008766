import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import "./nuestros-servicios.scss"
import { ServiceCard } from "../components/service-card/service-card"

import {
  faFax,
  faPhoneSquareAlt,
  faFileArchive,
  faFileInvoice,
  faFile,
  faGavel,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fade from "react-reveal/Fade"
import config from "react-reveal/globals"
import { StringyWindup } from "../components/stringy-windup/stringy-windup"
import Note from "../components/note/note"
import { GlobalStateProvider } from "../utils/globalStateProvider"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"
import cn from "classnames"

config({ ssrFadeout: true })

export default function ServiciosProfesionales() {
  const [status, setStatus] = useState("")

  return (
    <GlobalStateProvider>
      <React.Fragment>
        <SEO title="Servicios a profesionales" />
        <Layout pageId={cn("our-services", "professionals")}>
          <div className="our-services-container">
            <h1>Servicios a Profesionales</h1>

            <StringyWindup
              text="negligenciasdeabogados.net te
                        ofrece un nuevo servicio exclusivo para los abogados"
              callbackFn={() => setStatus("step-1")}
            ></StringyWindup>
            <Fade left duration={2000} when={status === "step-1"}>
              <ul className="cards grid grid--desktop-3cols grid--smartphone-1cols">
                <li key="cuestionario">
                  <ServiceCard
                    title="Abogado reclamante"
                    key="abogado-reclamante"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faGavel}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "Aclarar <strong>camino a seguir</strong>",
                      "Te asesoramos en el <strong>enfoque</strong>",
                      "<strong>Implicaciones deontológicas</strong>",
                      "<strong>Póliza</strong> de RC profesional",
                    ]}
                    ctaLink="/contacto"
                    isFree={true}
                    showModalNoAvailable={false}
                  />
                </li>

                <li key="abogado-reclamado">
                  <ServiceCard
                    title="Abogado reclamado"
                    key="abogado-reclamado"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faGavel}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "¿Has cometido una <strong>negligencia</strong>?",
                      "¿Te están <strong>reclamando</strong>?",
                      "Conoce posibles <strong>repercusiones</strong>",
                    ]}
                    ctaLink="/contacto"
                    showModalNoAvailable={false}
                  />
                </li>
              </ul>
            </Fade>

            {/* <Fade right duration={3000} when={status === "step-1"}>
              <section className="notes">
                <Note>Máxima confidencialidad en los datos</Note>
                <br></br>
              </section>
            </Fade> */}
          </div>
          <FullScreenModal />
        </Layout>
      </React.Fragment>
    </GlobalStateProvider>
  )
}
